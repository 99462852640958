<template>
  <div
    class="card mb-4"
    v-if="
      role.length > 0 && this.$store.state.getMovie.status.rolePlayPersonLoading
    "
  >
    <div class="card-header bg-success">
      <h4 class="text-center text-white my-2">
        فیلم هایی که {{ person.name }} در آن نقش آفرینی نموده است.
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div
          class="col-sm-6 col-md-4 col-lg-3"
          v-for="movie in role"
          :key="movie.id"
        >
          <cardViewMovie
            :movie="movie"
            :size="imageSizeMovie('w342')"
          ></cardViewMovie>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardViewMovie from "@/components/cardView/cardViewMovie";
export default {
  name: "RolePlayPerson",
  components: { CardViewMovie },
  props: {
    person: {
      type: Object,
      required: true,
    },
    role: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
